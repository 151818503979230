import { getCurrentInstance, toRefs, reactive, ref, provide, onBeforeMount, onMounted } from "vue";
import { baseUrlImg } from '@/utils/common'
import { useRouter } from 'vue-router'

export default {
	setup(props, context) {
		
		//开始创建组件之前
		const { proxy } = getCurrentInstance();

		const imgBaseUrl = baseUrlImg();

		const router = useRouter();
		
		onBeforeMount(async () =>{
			console.log('beforeMount !')
			// 验证是否是企业还是设备
			await proxy.$http({
				url: proxy.$http.adornUrl(`/app/qr/judgment`),
				method: 'post',
				data: proxy.$http.adornData({
					'code': proxy.$router.currentRoute.value.params.id
				})
			})
			.then(res => {
				if(res.data.code===0) {
					// 1是企业 2是设备
					if(res.data.result == 1){
						router.push({
							path: '/enterpriseJump/' + proxy.$router.currentRoute.value.params.id
						});
					}
				}
			})
		}) 
		
		onMounted(() => {
			queryfacilityDetail()
		})

		const dataAll = reactive({
			facility: ref([]),
			show: ref(false),
			usersTSMap: ref(''),
			operatorName: ref(''),
			facilityQrNumber: ref(proxy.$router.currentRoute.value.params.id),
			urlImg: [],
			enterpriseModel: ref(''),

			enterprisePopShow: ref(false),
			enterpriseName: ref(''),
			pageNum: ref(1),
			enterpriseList: ref([]),
			enterpriseDownLoading: ref(false),
			enterpriseFinished: ref(true),
			enterpriseOffset: 100,
			loginshow: false
		});
		
		dataAll.enterpriseModel = sessionStorage.getItem('enterpriseModel')

		const showPopup = (url) => {
			dataAll.urlImg = [url];
			dataAll.show = true;
		};

		let queryfacilityDetail = async () => {
			await proxy.$http({
				url: proxy.$http.adornUrl(`/app/qr/facilityDetails`),
				method: 'post',
				data: proxy.$http.adornData({
					'facilityQrNumber': proxy.$router.currentRoute.value.params.id
				})
			}).then(({ data }) => {
				if (data && data.code === 0) {
					dataAll.facility = data.data;
					if(dataAll.facility) {
						sessionStorage.setItem('enterpriseName', dataAll.facility.enterpriseName)
						sessionStorage.setItem('enterpriseId', dataAll.facility.facilityEnterprise)
					}
					let nameArr = dataAll.facility&&dataAll.facility.userSupervise?dataAll.facility.userSupervise.map((u) => {return u.userNikeName}):''
					dataAll.usersTSMap = nameArr? (nameArr.length>1? nameArr.join('、') : nameArr[0]) : '暂无'
					let opeatorNameArray = dataAll.facility&&dataAll.facility.userOperation?dataAll.facility.userOperation.map(o=>{ return o.userNikeName}): []
					dataAll.operatorName = opeatorNameArray?(opeatorNameArray.length>1? opeatorNameArray.join('、') : opeatorNameArray[0]): '暂无'
				} else if (data.code === 401) {
					sessionStorage.removeItem("enterpriseName")
					sessionStorage.removeItem("enterpriseId")
					sessionStorage.setItem("userToken", "");
					router.push({
						path: '/enterpriseJump/' + proxy.$router.currentRoute.value.params.id
					});
				} else {
					sessionStorage.removeItem("enterpriseName")
					sessionStorage.removeItem("enterpriseId")
					proxy.$toast(data.msg);
				}
			})
		}

	

		provide('dataAll', dataAll)

		const isNull = (value) => {
			if (value == "" || value == null) {
				return "暂无";
			} else {
				return value;
			}
		}

		// TODO 企业列表显示，选中企业，跳转绑定页面
		const searchEnterprise = () => {
			dataAll.enterpriseDownLoading = true
			proxy.$http({
				url: proxy.$http.adornUrl(`/app/enterprise/selectEnterpriseByName`),
				method: 'post',
				headers:{
					token: sessionStorage.getItem("userToken")
			  	},
				data: proxy.$http.adornData({
					'enterpriseName': dataAll.enterpriseName,
					'page': dataAll.pageNum,
					'limit': 10
				})
			}).then(({data}) => {
				if(data.code==0) {
					dataAll.enterpriseList.push(...data.page.records) 
					if(dataAll.pageNum >= data.page.pages) {
						dataAll.enterpriseFinished=true
					} else{
						dataAll.enterpriseFinished = false
					}
				}else if (data.code === 401) {
					sessionStorage.setItem("userToken", "");
					router.push({
						path: '/enterpriseJump/' + proxy.$router.currentRoute.value.params.id
					});
				} 
				dataAll.enterpriseDownLoading = false
			})
		}
		const enterpriseCancel=() => {
			dataAll.enterprisePopShow = false
		}
		const loadEnterprise = () => {
			dataAll.pageNum++;
			searchEnterprise()
		}

		// 选中企业
		const checkEnterprise = (item) => {
			sessionStorage.setItem('enterpriseName', item.enterpriseName)
			sessionStorage.setItem('enterpriseId', item.enterpriseId)
			sessionStorage.setItem('enterpriseGame', item.enterpriseGame)
			sessionStorage.setItem('userTsMapList', item.userTsMapList)
			// sessionStorage.setItem('facilityQrNumber', proxy.$router.currentRoute.value.params.id)
			router.push({path: '/equipmentAdd', query: { facilityQrNumber: proxy.$router.currentRoute.value.params.id} })
		}

		const closeLogin = () => {
			dataAll.loginshow = false
			setTimeout(()=>{
				let token = sessionStorage.getItem('userToken') 
				if(!dataAll.facility && token) {
					dataAll.enterprisePopShow = true
				}
			},100)
		}
		
		const jumpToEditEQ = () => {
			let userToken = sessionStorage.getItem("userToken");
			if (!userToken) { // 未登录
				dataAll.loginshow = true;
			}
			else if(!dataAll.facility) { // 未查询到设备
				dataAll.enterprisePopShow = true
			} 
			else {
				router.push({path: '/equipmentEdit/'+ dataAll.facilityQrNumber})
			}
		}
		
		return {
			...toRefs(dataAll),
			showPopup,
			isNull,
			imgBaseUrl,
			jumpToEditEQ,
			searchEnterprise,
			enterpriseCancel,
			loadEnterprise,
			checkEnterprise,
			closeLogin
		}
	}
}
