<template>
	<div class="facility">
		<div class="top-title">
			<img class="left-img" src="../img/title_img.png" />
			<span>特种设备信息</span>
			<img class="right-img" @click="jumpToEditEQ" src="~@/img/add.png" />
		</div>

		<div v-if="facility" class="alls">
			<div class="content">
				<div class="content-t">设备名称</div>
				<div class="content-c">{{isNull(facility.facilityName)}}</div>
			</div>
			<div class="content">
				<div class="content-t">所属单位</div>
				<div class="content-c">{{isNull(facility.enterpriseName)}}</div>
			</div>

			<div class="content">
				<div class="content-t">档案编号</div>
				<div class="content-c">{{isNull(facility.facilityArchives)}}</div>
			</div>

			<div class="content">
				<div class="content-t">操作人员</div>
				<div class="content-c">{{operatorName}}</div>
			</div>
			<div class="content">
				<div class="content-t">公司地址</div>
				<div class="content-c">{{isNull(facility.facilityAddress)}}</div>
			</div>
			<div class="content content-flex">
				<div class="content-f">
					<div class="content-t">注册代码</div>
					<div class="content-c">{{isNull(facility.facilityRegisterNumber)}}</div>
				</div>
				<div class="content-f">
					<div class="content-t">注册登记日期</div>
					<div class="content-c">
						<span>{{isNull(facility.facilityRegister)}}</span>
					</div>
				</div>
			</div>
			<div class="content content-flex">
				<div class="content-f">
					<div class="content-t">使用登记证号</div>
					<div class="content-c">{{isNull(facility.facilityStarts)}}</div>
				</div>
				<div class="content-f">
					<div class="content-t">设计使用年限</div>
					<div class="content-c">
						<span>{{isNull(facility.facilityYear)}}</span>
					</div>
				</div>
			</div>
			<div class="content">
				<div class="content-t">制造单位</div>
				<div class="content-c">{{isNull(facility.facilityMake)}}</div>
			</div>
			<div class="content content-flex">
				<div class="content-f">
					<div class="content-t">出厂编号</div>
					<div class="content-c">{{isNull(facility.facilityNumber)}}</div>
				</div>
				<div class="content-f">
					<div class="content-t">设备检验日期</div>
					<div class="content-c">
						<span>{{isNull(facility.facilityCheck)}}</span>
					</div>
				</div>
			</div>
			<div class="content content-flex">
				<div class="content-f">
					<div class="content-t">下次设备检验日期</div>
					<div class="content-c">{{isNull(facility.facilityCheckNext)}}</div>
				</div>
				<div class="content-f">
					<div class="content-t">设备使用场所</div>
					<div class="content-c">
						<span>{{isNull(facility.facilityPlace)}}</span>
					</div>
				</div>
			</div>
			<div class="content">
				<div class="content-t">重点监控设备</div>
				<div class="content-c">
					<span v-if="facility.facilityFocus==1">重点</span>
					<span v-else-if="facility.facilityFocus==0">普通</span>
					<span v-else>未知</span>
				</div>
			</div>
			<div class="content content-flex">
				<div class="content-f">
					<div class="content-t">省市区街道</div>
					<div class="content-c">{{isNull(facility.facilityStreet)}}</div>
				</div>
				<div class="content-f">
					<div class="content-t">设备型号</div>
					<div class="content-c">
						<span>{{isNull(facility.facilityModel)}}</span>
					</div>
				</div>
			</div>
			<div class="content content-flex">
				<div class="content-f">
					<div class="content-t">设备状态</div>
					<div class="content-c">{{isNull(facility.facilityStateEn)}}</div>
				</div>
				<div class="content-f">
					<div class="content-t">设备类型</div>
					<div class="content-c">
						<span>{{isNull(facility.facilityTypeName)}}</span>
					</div>
				</div>
			</div>

			<div class="content">
				<div class="content-t">监管人员</div>
				<div class="content-c">{{usersTSMap}}</div>
			</div>
			
			<div class="content content-flex">
				<div class="content-f">
					<div class="content-t">使用方</div>
					<div class="content-c">{{isNull(facility.facilityOtherUser)}}</div>
				</div>
				<div class="content-f">
					<div class="content-t">所属方</div>
					<div class="content-c">
						<span>{{isNull(facility.facilityOwner)}}</span>
					</div>
				</div>
			</div>

			<div class="content">
				<div class="content-t">备注</div>
				<div class="content-c">
					<div class="c-c-text">
						<div>{{isNull(facility.facilityNote)}}</div>
					</div>
				</div>
			</div>

			<div class="content" style="border-bottom: none;margin-bottom: 1.333333rem;">
				<div class="content-t">上传设备图片</div>
				<div class="content-c content-c1">
					<span v-if="facility.facilitySrcList==null||facility.facilitySrcList==''">暂无</span>
					<img v-else v-for="(item,index) in facility.facilitySrcList" :key="index" :src="`${imgBaseUrl}${item}`" @click="showPopup(`${imgBaseUrl}${item}`)" />
				</div>
			</div>

			<van-image-preview :closeable="true" :show-index="false" v-model:show="show" :images="urlImg"></van-image-preview>
		</div>

		<van-popup class="enterpop" v-model:show="enterprisePopShow" round position="bottom" :style="{ width: '100%' }">
			<van-search v-model="enterpriseName" clearable show-action placeholder="请搜索企业名称关键词并选择" @search="searchEnterprise">
				<template #action>
					<van-button size="small" round @click="searchEnterprise">搜索</van-button>
					<van-button size="small" round @click="enterpriseCancel">取消</van-button>
				</template>
			</van-search>
			<van-list v-model:loading="enterpriseDownLoading" :finished="enterpriseFinished" finished-text="没有更多了" :offset="enterpriseOffset" @load="loadEnterprise">
				<van-cell v-for="(item) in enterpriseList" :key="item.enterpriseId" :title="item.enterpriseName" @click="checkEnterprise(item)" />
			</van-list>
		</van-popup>

		<equipment-no v-if="!facility"></equipment-no>

		<login-alert v-if="loginshow" :showLogin="loginshow" :isTS="true" @close="closeLogin"> </login-alert>
	</div>
</template>

<style scoped>
	.top-title {
		background-color: #eae6e5;
		color: #232122;
		width: 100%;
		height: 1.173333rem;
		line-height: 1.173333rem;
		position: fixed;
	}

	.top-title span {
		font-size: 0.48rem;
		font-weight: 550;
		letter-spacing: 0.026667rem;
		margin-left: 1.493333rem;
	}

	.top-title .left-img {
		height: 0.533333rem;
		position: absolute;
		top: 0.32rem;
		left: 0.693333rem;
	}
	.top-title .right-img {
		height: 0.533333rem;
		position: absolute;
		top: 0.32rem;
		right: 0.693333rem;
		z-index: 290;
	}

	.alls {
		width: 100%;
		padding-top: 1.173333rem;
		padding-bottom: 0.666667rem;
	}

	.content {
		width: 94%;
		margin-left: 3%;
		border-bottom: 1px #eeeeee solid;
	}

	.content-t {
		color: #2a8df0;
		font-size: 0.373333rem;
		padding-top: 0.186667rem;
		width: 100%;
		text-indent: 0.133333rem;
	}

	.content-c {
		color: #464646;
		font-size: 0.373333rem;
		padding-bottom: 0.186667rem;
		margin-top: 0.053333rem;
		text-indent: 0.133333rem;
		width: 100%;
	}

	.content-c1 {
		overflow-x: scroll;
		white-space: nowrap;
	}

	.content-flex {
		display: flex;
	}

	.content-f {
		flex: 1;
	}

	.content-c span {
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}

	.content-c img {
		height: 1.973333rem;
		padding: 0.133333rem 0.133333rem 0rem 0.133333rem;
	}

	.c-c-text {
		background-color: #eeeeee;
		border-radius: 0.133333rem;
		width: 100%;
		min-height: 2.133333rem;
		margin-top: 0.133333rem;
	}

	.c-c-text div {
		padding: 0.133333rem;
	}

	.button-all {
		width: 90%;
		margin-left: 5%;
		margin-top: 2.666667rem;
		position: relative;
	}

	.button-div {
		color: white;
		height: 1.173333rem;
		line-height: 1.173333rem;
		text-align: center;
		border-radius: 0.266667rem;
		font-size: 0.426667rem;
		width: 48%;
		display: inline-block;
	}

	.button-lan {
		background-color: #4492e5;
	}

	.button-cheng {
		background-color: #ec6f2d;
		position: absolute;
		right: 0rem;
	}

	.enterpriseNo {
		position: relative;
		top: 1.23rem;
	}

	.enterpriseNo > /deep/ .alls > .imgTsT {
		width: 30%;
		margin-left: 60%;
		margin-top: 0px;
	}

	.van-icon-close::before {
		color: #464646;
	}

	::v-deep.van-popup__close-icon {
		color: #2a8df0;
	}

	.enterpop > .van-list {
		height: 11rem;
	}

	.enterpop > .van-search > .van-search__action > .van-button {
		padding: 0.1333rem 0.3333rem;
		margin: 0rem 0.1rem;
	}
</style>

<script>
	import EquipmentD from "../js/EquipmentD";
	import LoginAlert from "@/components/login";
	import EquipmentNo from "./EquipmentNo.vue";

	export default {
		components: { LoginAlert, EquipmentNo },
		...EquipmentD,
	};
</script>
